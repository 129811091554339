import React from 'react';
import styled from 'styled-components';
import { height } from 'styled-system';
import { Text } from 'rebass/styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import GlobalFooter from 'components/global-footer-rebrand';
import MarketingHeader from 'marketing/components/marketing-header';
import Wrapper from 'components/wrapper';
import MetaTags from 'components/meta-tags';
import { Link } from 'components/core';

export default function TermsPage() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`sm`));

  return (
    <Wrapper>
      <MetaTags variant='terms' />
      <MarketingHeader variant='legal' />
      <PageContentContainer isMobile={isMobile}>
        {/* eslint-disable */}
        <H1>Terms Of Service</H1>
        <br />
        <P>Date of Last Revision: September 28, 2023</P>
        <br />
        <H2>Welcome to Dutchie!</H2>
        <P>
          Courier Plus Inc. (“Dutchie,” “we,” “us,” “our”) provides its services (described below) to you through its
          website located at www.dutchie.com (the “Site”) and through its mobile applications and related services
          (collectively, such services, including any new features and applications, and the Site, the “Service(s)”),
          subject to the following Terms of Service (as amended from time to time, the “Terms of Service”). We reserve
          the right, at our sole discretion, to change or modify portions of these Terms of Service at any time. If we
          do this, we will post the changes on this page and will indicate at the top of this page the date these terms
          were last revised. We will also notify you, either through the Services user interface, in an email
          notification or through other reasonable means. Any such changes will become effective no earlier than
          fourteen (14) days after they are posted, except that changes addressing new functions of the Services or
          changes made for legal reasons will be effective immediately. Your continued use of the Service after the date
          any such changes become effective constitutes your acceptance of the new Terms of Service.
        </P>
        <P>
          <Bold>
            <i>
              PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN AN AGREEMENT TO ARBITRATE AND OTHER
              IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE
              REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL
              ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST DUTCHIE ON AN INDIVIDUAL
              BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU
              WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN
              INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US RESOLVED BY A JURY OR
              IN A COURT OF LAW.
            </i>
          </Bold>
        </P>
        <P>
          In addition, when using certain services, you will be subject to any additional terms applicable to such
          services that may be posted on the Service from time to time, including, without limitation, the Privacy
          Policy located at{' '}
          <Link href='/privacy'>
            <a href='https://dutchie.com/privacy'>https://dutchie.com/privacy</a>
          </Link>
          . All such terms are hereby incorporated by reference into these Terms of Service.
        </P>
        <br />
        <SectionHeader>Key Points</SectionHeader>
        <P>
          Dutchie is not a marijuana dispensary, collective or cooperative. It is a service that connects cannabis
          collectives or cooperatives (collectively, "Dispensaries") with their customers and/or primary caregivers.
          Dutchie does not offer delivery services, set pricing, or maintain any inventory of cannabis. Dutchie offers
          you the convenience of receiving information about the Dispensaries.
        </P>
        <P>
          Dutchie does not act in any way as a marijuana delivery service and so we have no responsibility or liability
          for any delivery services provided to you. Dutchie neither endorses nor is responsible for the accuracy or
          reliability of any information, content, quality of goods or services offered by the Dispensaries and cannot
          make any representations or warranties with respect to whether the Dispensaries are compliant with applicable
          rules and regulations. All questions regarding Dispensaries' products and/or services featured on the Site
          and/or the Services should be directed to the appropriate Dispensaries.
        </P>
        <P>
          The information provided through the Services is not intended to be a substitute for professional medical
          advice, diagnosis, cure or treatment. Dutchie does not recommend or endorse any specific physicians, products,
          tests, procedures, opinions or other information that may be mentioned on the Site and/or the Services.
        </P>
        <P>
          Always seek the advice of a qualified medical professional with any questions you may have regarding your
          condition. Do not disregard professional medical advice or delay in seeking it because of Information on the
          Site and/or the Services. Reliance on any Information is solely at your own risk.
        </P>
        <br />
        <SectionHeader>Disclaimers and Acknowledgements</SectionHeader>
        <P>
          <Bold>Dutchie is a Technology Platform:</Bold> You expressly agree and acknowledge that Dutchie: (i) is a
          technology service provider; (ii) is not a licensed medical or adult-user cannabis retailer, manufacturer or
          brand; (iii) does not itself provide delivery services to consumers; and (iv) does not cultivate, manufacture,
          package, label, test , or sell medical or adult-use cannabis. It is the sole responsibility of the Dispensary
          to offer its services to you in a legal and compliant manner, which are facilitated through its use of the
          Site and Services.
        </P>
        <P>
          <Bold>Compliance with Federal Law:</Bold> Marijuana is included on Schedule 1 under the United States
          Controlled Substances Act. Under the federal laws of the United States of America, manufacturing,
          distributing, dispensing or possession of marijuana is illegal, and individuals are subject to arrest and/or
          prosecution for doing so. You acknowledge that it is your responsibility to ensure compliance with these laws.
          Dutchie disclaims any and all liability relating to the use of its website and applications in violation of
          any federal laws, rules and/or regulations.
        </P>
        <P>
          <Bold>Compliance with State Law:</Bold> You expressly acknowledge that the Site is for those residing in
          states with laws regulating the use of medical or recreational cannabis and that the laws surrounding
          recreational and medical use are established pursuant to their respective State laws. You expressly
          acknowledge and assume full responsibility for cooperating with the laws of the state and municipality where
          you reside. Further, Dutchie disclaims any and all liability relating to the use of its website and
          applications in violation of any state laws, rules and/or regulations.
        </P>
        <P>
          <Bold>Health Information:</Bold> You expressly acknowledge, agree, and understand that any statements relating
          to cannabis goods, accessories or any other products available on the Site or through the Services have not
          been evaluated by the FDA. Neither the products nor the ingredients in any of the products have been approved
          or endorsed by the FDA or any regulatory agency. The products available on the Site or through the Services
          are not intended to diagnose, treat, cure, mitigate, or prevent any medical condition, illness, or disease in
          humans or animals. The information provided is designed for education purposes only and is not intended to be
          a substitute for informed medical advice or care. If you are pregnant, nursing, taking medication, or have a
          medical condition, we suggesting consulting with a physician before using and products offered or purchased
          through the Site or Services.
        </P>
        <br />
        <SectionHeader>Access and Use of the Service</SectionHeader>
        <P>
          <Bold>Services Description:</Bold> Dutchie connects cannabis collectives and cooperatives (collectively,
          “Dispensaries”) with their customers and/or primary caregivers (the “Services”).
        </P>
        <P>
          <Bold>Your Registration Obligations:</Bold> You may be required to register with Dutchie in order to access
          and use certain features of the Service. If you choose to register for the Service, you agree to provide and
          maintain true, accurate, current and complete information about yourself as prompted by the Service’s
          registration form. Registration data and certain other information about you are governed by our Privacy
          Policy. You must be at least 18 years old to use the Services. By using the Services, you expressly represent
          and warrant that you are at least 18 years old, are legally entitled to enter into these Terms of Service, and
          have the right, authority, and capacity to enter into and abide by the terms and conditions of these Terms of
          Service. You agree that if you are not at least 18 years old, you are prohibited from using, and will not
          attempt to use, the Services.
        </P>
        <P>
          <Bold>Member Account, Password and Security:</Bold> You are responsible for maintaining the confidentiality of
          your password and account, if any, and are fully responsible for any and all activities that occur under your
          password or account. Under some circumstances, as part of the process of creating an account, you will be
          asked to select a username and password. Dutchie may refuse to grant you a username that impersonates someone
          else, is or may be illegal, is or may be protected by trademark or other proprietary rights law, is
          disrespectful, vulgar or otherwise offensive, or may cause confusion, as determined by us in our sole
          discretion. You agree to (a) immediately notify Dutchie of any unauthorized use of your password or account or
          any other breach of security, and (b) ensure that you exit from your account at the end of each session when
          accessing the Service. Dutchie will not be liable for any loss or damage arising from your failure to comply
          with this Section.
        </P>
        <P>
          <Bold>Modifications to Service:</Bold> Dutchie reserves the right to modify or discontinue, temporarily or
          permanently, the Service (or any part thereof) with or without notice. You agree that Dutchie will not be
          liable to you or to any third party for any modification, suspension or discontinuance of the Service.
        </P>
        <P>
          <Bold>General Practices Regarding Use and Storage:</Bold> You acknowledge that Dutchie may establish general
          practices and limits concerning use of the Service, including without limitation the maximum period of time
          that data or other content will be retained by the Service and the maximum storage space that will be allotted
          on Dutchie’s servers on your behalf. You agree that Dutchie has no responsibility or liability for the
          deletion or failure to store any data or other content maintained or uploaded by the Service. You acknowledge
          that Dutchie reserves the right to terminate accounts that are inactive for an extended period of time. You
          further acknowledge that Dutchie reserves the right to change these general practices and limits at any time,
          in its sole discretion, with or without notice.
        </P>
        <P>
          <Bold>Mobile Services:</Bold> The Service includes certain services that are available via a mobile device,
          including (i) the ability to upload content to the Service via a mobile device, (ii) the ability to browse the
          Service and the Site from a mobile device and (iii) the ability to access certain features through an
          application downloaded and installed on a mobile device (collectively, the “Mobile Services”). To the extent
          you access the Service through a mobile device, your wireless service carrier’s standard charges, data rates
          and other fees may apply. In addition, downloading, installing, or using certain Mobile Services may be
          prohibited or restricted by your carrier, and not all Mobile Services may work with all carriers or devices.
          By using the Mobile Services, you agree that we may communicate with you regarding Dutchie and other entities
          by SMS, MMS, text message or other electronic means to your mobile device and that certain information about
          your usage of the Mobile Services may be communicated to us. In the event you change or deactivate your mobile
          telephone number, you agree to promptly update your Dutchie account information to ensure that your messages
          are not sent to the person that acquires your old number.
        </P>
        <br />
        <SectionHeader>Conditions of Use</SectionHeader>
        <List>
          <Bold>User Conduct:</Bold> You are solely responsible for all code, video, images, information, data, text,
          software, music, sound, photographs, graphics, messages or other materials (“content”) that you upload, post,
          publish or display (hereinafter, “upload”) or email or otherwise use via the Service. The following are
          examples of the kind of content and/or use that is illegal or prohibited by Dutchie. Dutchie reserves the
          right to investigate and take appropriate legal action against anyone who, in Dutchie’s sole discretion,
          violates this provision, including without limitation, removing the offending content from the Service,
          suspending or terminating the account of such violators and reporting you to the law enforcement authorities.
          You agree to not use the Service to:
          <UL>
            <li>
              email or otherwise upload any content that (i) infringes any intellectual property or other proprietary
              rights of any party; (ii) you do not have a right to upload under any law or under contractual or
              fiduciary relationships; (iii) contains software viruses or any other computer code, files or programs
              designed to interrupt, destroy or limit the functionality of any computer software or hardware or
              telecommunications equipment; (iv) poses or creates a privacy or security risk to any person; (v)
              constitutes unsolicited or unauthorized advertising, promotional materials, commercial activities and/or
              sales, “junk mail,” “spam,” “chain letters,” “pyramid schemes,” “contests,” “sweepstakes,” or any other
              form of solicitation; (vi) is unlawful, harmful, threatening, abusive, harassing, tortious, excessively
              violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s privacy, hateful
              racially, ethnically or otherwise objectionable; or (vii) in the sole judgment of Dutchie, is
              objectionable or which restricts or inhibits any other person from using or enjoying the Service, or which
              may expose Dutchie or its users to any harm or liability of any type;
            </li>
            <li>
              interfere with or disrupt the Service or servers or networks connected to the Service, or disobey any
              requirements, procedures, policies or regulations of networks connected to the Service; or
            </li>
            <li>
              violate any applicable local, state, national or international law, or any regulations having the force of
              law;
            </li>
            <li>
              impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a
              person or entity;
            </li>
            <li>solicit personal information from anyone under the age of 18;</li>
            <li>
              harvest or collect email addresses or other contact information of other users from the Service by
              electronic or other means for the purposes of sending unsolicited emails or other unsolicited
              communications;
            </li>
            <li>
              advertise or offer to sell or buy any goods or services for any business purpose that is not specifically
              authorized;
            </li>
            <li>
              further or promote any criminal activity or enterprise or provide instructional information about illegal
              activities; or
            </li>
            <li>
              obtain or attempt to access or otherwise obtain any materials or information through any means not
              intentionally made available or provided for through the Service.
            </li>
          </UL>
        </List>
        <P>
          <Bold>Fees:</Bold> To the extent the Service or any portion thereof is made available for any fee, you will be
          required to select a payment plan and provide Dutchie information regarding your credit card or other payment
          instrument. You represent and warrant to Dutchie that such information is true and that you are authorized to
          use the payment instrument. You will promptly update your account information with any changes (for example, a
          change in your billing address or credit card expiration date) that may occur. You agree to pay Dutchie the
          amount that is specified in the payment plan in accordance with the terms of such plan and this Terms of
          Service. Any fees that Dutchie may charge you for the Service are due immediately and are non-refundable. This
          no-refund policy shall apply at all times regardless of your decision to terminate your usage, our decision to
          terminate your usage, disruption caused to the Service either planned, accidental or intentional, or any
          reason whatsoever. We reserve the right to change Dutchie’s prices. Your continued use of the Service after
          the price change becomes effective constitutes your agreement to pay the changed amount. You shall be
          responsible for all taxes associated with the Services other than U.S. taxes based on Dutchie’s net income.
        </P>
        <P>
          <Bold>Special Notice for International Use; Export Controls:</Bold> The Site and the Services are controlled
          and operated from its facilities in the United States. Dutchie makes no representations that the Site or the
          Services are appropriate or available for use in other locations. Those who access or use the Site or the
          Services from other jurisdictions do so at their own volition and are entirely responsible for compliance with
          all applicable United States and local laws and regulations, including but not limited to export and import
          regulations. You represent and warrant that (i) you are not located in a country that is subject to a U.S.
          Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country;
          and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties. Software (defined
          below) available in connection with the Service and the transmission of applicable data, if any, is subject to
          United States export controls. No Software may be downloaded from the Service or otherwise exported or
          re-exported in violation of U.S. export laws. Downloading or using the Software is at your sole risk.
          Recognizing the global nature of the Internet, you agree to comply with all local rules and laws regarding
          your use of the Service, including as it concerns online conduct and acceptable content.
        </P>
        <P>
          <Bold>Commercial Use:</Bold> Unless otherwise expressly authorized herein or in the Service, you agree not to
          display, distribute, license, perform, publish, reproduce, duplicate, copy, create derivative works from,
          modify, sell, resell, exploit, transfer or upload for any commercial purposes, any portion of the Service, use
          of the Service, or access to the Service. The Service is for your personal use.
        </P>
        <br />
        <SectionHeader>Transactions Between Buyers and Sellers</SectionHeader>
        <P>
          Through the Site, Dutchie may provide electronic web-based platforms for exchanging information between buyers
          and sellers of products and services. However, for any Services, Dutchie does not represent either the seller
          or the buyer in specific transactions. Dutchie does not control and is not liable or responsible for the
          quality, safety, lawfulness or availability of the products or services offered for sale on the Site, the
          ability of the sellers to complete a sale or the ability of buyers to complete a purchase.
        </P>
        <P>
          You are hereby made aware that there may be risks of dealing with people acting under false pretenses. Dutchie
          uses several techniques to verify the accuracy of certain information our users provide us when they register
          on the Site or for the Services. However, because user verification on the Internet is difficult, Dutchie
          cannot and does not confirm each user's purported identity. We encourage you to use various means, as well as
          common sense, to evaluate with whom you are dealing.
        </P>
        <P>
          Buyers and sellers accessing or using the Site or Services shall assume the risks of conducting any purchase
          and sale transactions in connection with or through the Site or Services. Buyer and sellers accessing or using
          the Site or Services shall also fully assume all risks of liability or harm of any kind arising out of or in
          connection with any subsequent activity relating to the products or services that are the subject of the
          transactions on the Site. Examples of such risks shall include, but are not limited to, mis-representation of
          products and services, fraudulent schemes, unsatisfactory product quality, failure to meet specifications,
          defective or dangerous products, unlawful products, delay or default in delivery or payment, cost
          mis-calculations, breach of warranty, breach of contract, transportation accidents, the risk that the
          manufacture, importation, export, distribution, offer, display, purchase, sale and/or use of products or
          services offered or displayed on the Site may violate or may be asserted to violate third party rights, and
          the risk that you may incur costs of defense or other costs in connection with third parties’ assertion of
          such third party rights, or in connection with any claims by any party that they are entitled to defense or
          indemnification in relation to the assertion of rights, demands or claims by claimants of third party rights.
          Examples of such risks also include the risk of claims from consumers, other purchasers, end-users of products
          or other third parties that they have suffered injuries or harm from their use of the products obtained
          through the Site or Services. All of the foregoing risks are referred to as "Transaction Risks". Dutchie is
          not liable or responsible for any damages, claims, liabilities, costs, harm, inconveniences, business
          disruptions or expenditures of any kind that may arise a result of or in connection with any Transaction
          Risks.
        </P>
        <P>
          To the extent that you have placed an eCommerce order with a New York-based Dispensary and another individual
          will be picking up the order on your behalf (or on behalf of the individual for whom the order was placed),
          you attest that the individual picking up the order on your behalf is 21 years of age or older.
        </P>
        <P>
          You agree to provide all information and materials as may be reasonably required by Dutchie in connection with
          your transactions conducted on, through or as a result of use of the Site or Services. Dutchie has the right
          to suspend or terminate your account if you fail to provide the required information and materials without
          liability for any losses or damages arising out of or in connection with such suspension or termination.
        </P>
        <P>
          In the event that you have a dispute with any party to a transaction, you agree to release and indemnify
          Dutchie (and our agents, affiliates, directors, officers and employees) from all claims, demands, actions,
          proceedings, costs, expenses and damages (including without limitation any actual, special, incidental or
          consequential damages) arising out of or in connection with such dispute or the transaction.
        </P>
        <br />
        <SectionHeader>Product Descriptions</SectionHeader>
        <P>
          Dutchie attempts to be as accurate as possible in product descriptions or images for products available
          through the Services. However, Dutchie is not a Dispensary and does not itself package, test, or label the
          products available on the Site or through the Services. Dutchie does not warrant that product descriptions,
          images, or other content available on the Site or through the Services is accurate, complete, reliable,
          current, or error-free.
        </P>
        <P>
          You acknowledge and agree that that amounts in product descriptions on the Site and through the Services are
          averages or estimates, and that amounts may vary for individual items, packages, or orders that you receive.
          Amounts on the Site include, without limitation, the level (by weight, mass, volume, or percentage) of THC,
          CBD, other cannabinoids, and terpenes in a product, the total weight, mass, or volume of a product, the size,
          number of individual items, or services, in a product, and any other unit of measurement related to a product.
        </P>
        <br />
        <SectionHeader>Third Party Distribution Channels</SectionHeader>
        <P>
          Dutchie offers Software applications that may be made available through the Apple App Store, Android
          Marketplace or other distribution channels (“Distribution Channels”). If you obtain such Software through a
          Distribution Channel, you may be subject to additional terms of the Distribution Channel. These Terms of
          Service are between you and us only, and not with the Distribution Channel. To the extent that you utilize any
          other third party products and services in connection with your use of our Services, you agree to comply with
          all applicable terms of any agreement for such third party products and services.
        </P>
        <List>
          With respect to Software that is made available for your use in connection with an Apple-branded product (such
          Software, “Apple-Enabled Software”), in addition to the other terms and conditions set forth in these Terms of
          Service, the following terms and conditions apply:
          <UL>
            <li>
              Dutchie and you acknowledge that these Terms of Service are concluded between Dutchie and you only, and
              not with Apple Inc. (“Apple”), and that as between Dutchie and Apple, Dutchie, not Apple, is solely
              responsible for the Apple-Enabled Software and the content thereof.
            </li>
            <li>
              You may not use the Apple-Enabled Software in any manner that is in violation of or inconsistent with the
              Usage Rules set forth for Apple-Enabled Software in, or otherwise be in conflict with, the App Store Terms
              of Service.
            </li>
            <li>
              Your license to use the Apple-Enabled Software is limited to a non-transferable license to use the
              Apple-Enabled Software on an iOS Product that you own or control, as permitted by the Usage Rules set
              forth in the App Store Terms of Service.
            </li>
            <li>
              Apple has no obligation whatsoever to provide any maintenance or support services with respect to the
              Apple-Enabled Software.
            </li>
            <li>
              Apple is not responsible for any product warranties, whether express or implied by law. In the event of
              any failure of the Apple-Enabled Software to conform to any applicable warranty, you may notify Apple, and
              Apple will refund the purchase price for the Apple-Enabled Software to you, if any; and, to the maximum
              extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect
              to the Apple-Enabled Software, or any other claims, losses, liabilities, damages, costs or expenses
              attributable to any failure to conform to any warranty, which will be Dutchie’s sole responsibility, to
              the extent it cannot be disclaimed under applicable law.
            </li>
            <li>
              Dutchie and you acknowledge that Dutchie, not Apple, is responsible for addressing any claims of you or
              any third party relating to the Apple-Enabled Software or your possession and/or use of that Apple-Enabled
              Software, including, but not limited to: (i) product liability claims; (ii) any claim that the
              Apple-Enabled Software fails to conform to any applicable legal or regulatory requirement; and (iii)
              claims arising under consumer protection or similar legislation.
            </li>
            <li>
              In the event of any third party claim that the Apple-Enabled Software or the end-user’s possession and use
              of that Apple-Enabled Software infringes that third party’s intellectual property rights, as between
              Dutchie and Apple, Dutchie, not Apple, will be solely responsible for the investigation, defense,
              settlement and discharge of any such intellectual property infringement claim.
            </li>
            <li>
              You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government
              embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii)
              you are not listed on any U.S. Government list of prohibited or restricted parties.
            </li>
            <li>
              If you have any questions, complaints or claims with respect to the Apple-Enabled Software, they should be
              directed to Dutchie, at <a href='mailto:support@dutchie.com'>support@dutchie.com</a>.
            </li>
          </UL>
        </List>
        <P>
          Dutchie and you acknowledge and agree that Apple, and Apple’s subsidiaries, are third party beneficiaries of
          these Terms of Service with respect to the Apple-Enabled Software, and that, upon your acceptance of the terms
          and conditions of these Terms of Service, Apple will have the right (and will be deemed to have accepted the
          right) to enforce these Terms of Service against you with respect to the Apple-Enabled Software as a third
          party beneficiary thereof.
        </P>
        <br />
        <SectionHeader>Intellectual Property Rights</SectionHeader>
        <P>
          <Bold>Service Content, Software and Trademarks:</Bold> You acknowledge and agree that the Service may contain
          content or features (“Service Content”) that are protected by copyright, patent, trademark, trade secret or
          other proprietary rights and laws. Except as expressly authorized by Dutchie, you agree not to modify, copy,
          frame, scrape, rent, lease, loan, sell, distribute or create derivative works based on the Service or the
          Service Content, in whole or in part, except that the foregoing does not apply to your own User Content (as
          defined below) that you legally upload to the Service. In connection with your use of the Service you will not
          engage in or use any data mining, robots, scraping or similar data gathering or extraction methods. If you are
          blocked by Dutchie from accessing the Service (including by blocking your IP address), you agree not to
          implement any measures to circumvent such blocking (e.g., by masking your IP address or using a proxy IP
          address). Any use of the Service or the Service Content other than as specifically authorized herein is
          strictly prohibited. The technology and software underlying the Service or distributed in connection therewith
          are the property of Dutchie, our affiliates and our partners (the “Software”). You agree not to copy, modify,
          create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source
          code, sell, assign, sublicense, or otherwise transfer any right in the Software. Any rights not expressly
          granted herein are reserved by Dutchie.
        </P>
        <P>
          The Dutchie name and logos are trademarks and service marks of Dutchie (collectively the “Dutchie
          Trademarks”). Other Dutchie, product, and service names and logos used and displayed via the Service may be
          trademarks or service marks of their respective owners who may or may not endorse or be affiliated with or
          connected to Dutchie. Nothing in this Terms of Service or the Service should be construed as granting, by
          implication, estoppel, or otherwise, any license or right to use any of Dutchie Trademarks displayed on the
          Service, without our prior written permission in each instance. All goodwill generated from the use of Dutchie
          Trademarks will inure to our exclusive benefit.
        </P>
        <P>
          <Bold>Third Party Material:</Bold> Under no circumstances will Dutchie be liable in any way for any content or
          materials of any third parties (including users), including, but not limited to, for any errors or omissions
          in any content, or for any loss or damage of any kind incurred as a result of the use of any such content. You
          acknowledge that Dutchie does not pre-screen content, but that Dutchie and its designees will have the right
          (but not the obligation) in their sole discretion to refuse or remove any content that is available via the
          Service. Without limiting the foregoing, Dutchie and its designees will have the right to remove any content
          that violates these Terms of Service or is deemed by Dutchie, in its sole discretion, to be otherwise
          objectionable. You agree that you must evaluate, and bear all risks associated with, the use of any content,
          including any reliance on the accuracy, completeness, or usefulness of such content.
        </P>
        <P>
          <Bold>User Content Transmitted Through the Service:</Bold> With respect to the content or other materials you
          upload through the Service or share with other users or recipients (collectively, “User Content”), you
          represent and warrant that you own all right, title and interest in and to such User Content, including,
          without limitation, all copyrights and rights of publicity contained therein. By uploading any User Content
          you hereby grant and will grant Dutchie and its affiliated companies a nonexclusive, worldwide, royalty free,
          fully paid up, transferable, sublicensable, perpetual, irrevocable license to copy, display, upload, perform,
          distribute, store, modify and otherwise use your User Content in connection with the operation of the Service
          or the promotion, advertising or marketing thereof, in any form, medium or technology now known or later
          developed.
        </P>
        <P>
          You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information
          about the Service (“Submissions”), provided by you to Dutchie are non-confidential and Dutchie will be
          entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or
          otherwise, without acknowledgment or compensation to you.
        </P>
        <P>
          You acknowledge and agree that Dutchie may preserve content and may also disclose content if required to do so
          by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply
          with legal process, applicable laws or government requests; (b) enforce these Terms of Service; (c) respond to
          claims that any content violates the rights of third parties; or (d) protect the rights, property, or personal
          safety of Dutchie, its users and the public. You understand that the technical processing and transmission of
          the Service, including your content, may involve (a) transmissions over various networks; and (b) changes to
          conform and adapt to technical requirements of connecting networks or devices.
        </P>
        <P>
          <Bold>Copyright Complaints:</Bold> Dutchie respects the intellectual property of others, and we ask our users
          to do the same. If you believe that your work has been copied in a way that constitutes copyright
          infringement, or that your intellectual property rights have been otherwise violated, you should notify
          Dutchie of your infringement claim in accordance with the procedure set forth below.
        </P>
        <P>
          Dutchie will process and investigate notices of alleged infringement and will take appropriate actions under
          the Digital Millennium Copyright Act (“DMCA”) and other applicable intellectual property laws with respect to
          any alleged or actual infringement. A notification of claimed copyright infringement should be emailed to
          Dutchie’s Copyright Agent at <a href='mailto:support@dutchie.com'>support@dutchie.com</a> (Subject line: “DMCA
          Takedown Request”). You may also contact us by mail at:
        </P>
        <P>2728 NW Potts Ct. Ste 100, Bend, OR 97703</P>
        <List>
          To be effective, the notification must be in writing and contain the following information:
          <UL>
            <li>
              an electronic or physical signature of the person authorized to act on behalf of the owner of the
              copyright or other intellectual property interest;
            </li>
            <li>
              a description of the copyrighted work or other intellectual property that you claim has been infringed;
            </li>
            <li>
              a description of where the material that you claim is infringing is located on the Service, with enough
              detail that we may find it on the Service;
            </li>
            <li>your address, telephone number, and email address;</li>
            <li>
              a statement by you that you have a good faith belief that the disputed use is not authorized by the
              copyright or intellectual property owner, its agent, or the law;
            </li>
            <li>
              a statement by you, made under penalty of perjury, that the above information in your Notice is accurate
              and that you are the copyright or intellectual property owner or authorized to act on the copyright or
              intellectual property owner’s behalf.
            </li>
          </UL>
        </List>
        <List>
          <Bold>Counter-Notice:</Bold> If you believe that your User Content that was removed (or to which access was
          disabled) is not infringing, or that you have the authorization from the copyright owner, the copyright
          owner’s agent, or pursuant to the law, to upload and use the content in your User Content, you may send a
          written counter-notice containing the following information to the Copyright Agent:
          <UL>
            <li>your physical or electronic signature;</li>
            <li>
              identification of the content that has been removed or to which access has been disabled and the location
              at which the content appeared before it was removed or disabled;
            </li>
            <li>
              a statement that you have a good faith belief that the content was removed or disabled as a result of
              mistake or a misidentification of the content; and
            </li>
            <li>
              your name, address, telephone number, and email address, a statement that you consent to the jurisdiction
              of the federal court located within the District of Oregon and a statement that you will accept service of
              process from the person who provided notification of the alleged infringement.
            </li>
          </UL>
        </List>
        <P>
          If a counter-notice is received by the Copyright Agent, Dutchie will send a copy of the counter-notice to the
          original complaining party informing that person that it may replace the removed content or cease disabling it
          in 10 business days. Unless the copyright owner files an action seeking a court order against the content
          provider, member or user, the removed content may be replaced, or access to it restored, in 10 to 14 business
          days or more after receipt of the counter-notice, at our sole discretion.
        </P>
        <P>
          <Bold>Repeat Infringer Policy:</Bold> In accordance with the DMCA and other applicable law, Dutchie has
          adopted a policy of terminating, in appropriate circumstances and at Dutchie's sole discretion, users who are
          deemed to be repeat infringers. Dutchie may also at its sole discretion limit access to the Service and/or
          terminate the memberships of any users who infringe any intellectual property rights of others, whether or not
          there is any repeat infringement.
        </P>
        <br />
        <SectionHeader>Third Party Websites</SectionHeader>
        <P>
          The Service may provide, or third parties may provide, links or other access to other sites and resources on
          the Internet. Dutchie has no control over such sites and resources and Dutchie is not responsible for and does
          not endorse such sites and resources. You further acknowledge and agree that Dutchie will not be responsible
          or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection
          with use of or reliance on any content, events, goods or services available on or through any such site or
          resource. Any dealings you have with third parties found while using the Service are between you and the third
          party, and you agree that Dutchie is not liable for any loss or claim that you may have against any such third
          party.
        </P>
        <br />
        <SectionHeader>Social Networking Services</SectionHeader>
        <P>
          You may enable or log in to the Service via various online third party services, such as social media and
          social networking services like Facebook or Twitter (“Social Networking Services”). By logging in or directly
          integrating these Social Networking Services into the Service, we make your online experiences richer and more
          personalized. To take advantage of this feature and capabilities, we may ask you to authenticate, register for
          or log into Social Networking Services on the websites of their respective providers. As part of such
          integration, the Social Networking Services will provide us with access to certain information that you have
          provided to such Social Networking Services, and we will use, store and disclose such information in
          accordance with our Privacy Policy. For more information about the implications of activating these Social
          Networking Services and Dutchie’s use, storage and disclosure of information related to you and your use of
          such services within Dutchie (including your friend lists and the like), please see our Privacy Policy at{' '}
          <Link href='/privacy'>
            <a href='https://dutchie.com/privacy'>https://dutchie.com/privacy</a>
          </Link>
          . However, please remember that the manner in which Social Networking Services use, store and disclose your
          information is governed solely by the policies of such third parties, and Dutchie shall have no liability or
          responsibility for the privacy practices or other actions of any third party site or service that may be
          enabled within the Service.
        </P>
        <P>
          In addition, Dutchie is not responsible for the accuracy, availability or reliability of any information,
          content, goods, data, opinions, advice or statements made available in connection with Social Networking
          Services. As such, Dutchie is not liable for any damage or loss caused or alleged to be caused by or in
          connection with use of or reliance on any such Social Networking Services. Dutchie enables these features
          merely as a convenience and the integration or inclusion of such features does not imply an endorsement or
          recommendation.
        </P>
        <br />
        <SectionHeader>Indemnity and Release</SectionHeader>
        <P>
          You agree to release, indemnify and hold Dutchie and its affiliates and their officers, employees, directors
          and agents (collectively, “Indemnitees”) harmless from any from any and all losses, damages, expenses,
          including reasonable attorneys’ fees, rights, claims, actions of any kind and injury (including death) arising
          out of or relating to your use of the Service, any User Content, your connection to the Service, your
          violation of these Terms of Service or your violation of any rights of another. Notwithstanding the foregoing,
          you will have no obligation to indemnify or hold harmless any Indemnitee from or against any liability,
          losses, damages or expenses incurred as a result of any action or inaction of such Indemnitee. If you are a
          California resident, you waive California Civil Code Section 1542, which says: “A general release does not
          extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing
          the release, which if known by him must have materially affected his settlement with the debtor.” If you are a
          resident of another jurisdiction, you waive any comparable statute or doctrine.
        </P>
        <br />
        <SectionHeader>Disclaimer of Warranties</SectionHeader>
        <P>
          YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
          DUTCHIE EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT
          NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
          NON-INFRINGEMENT.
        </P>
        <P>
          DUTCHIE MAKES NO WARRANTY THAT (I) THE SERVICE WILL MEET YOUR REQUIREMENTS, (II) THE SERVICE WILL BE
          UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
          SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER
          MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.
        </P>
        <P>
          DUTCHIE MAKES NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY, SAFETY, TIMELINESS, QUALITY,
          SUITABILITY OR AVAILABILITY OF ANY SERVICES, PRODUCTS OR GOODS OBTAINED BY THIRD PARTIES THROUGH THE USE OF
          THE SERVICES. YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY
          THIRD-PARTY SERVICES OR PRODUCTS, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED BY LAW.
        </P>
        <br />
        <SectionHeader>Limitation of Liability</SectionHeader>
        <P>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT DUTCHIE WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR
          LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF DUTCHIE HAS BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM: (I)
          THE USE OR THE INABILITY TO USE THE SERVICE; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
          RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR
          TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
          TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (V) ANY OTHER MATTER
          RELATING TO THE SERVICE. IN NO EVENT WILL DUTCHIE’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES
          OF ACTION EXCEED THE AMOUNT YOU HAVE PAID DUTCHIE IN THE LAST SIX (6) MONTHS, OR, IF GREATER, ONE HUNDRED
          DOLLARS ($100).
        </P>
        <P>
          SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR
          EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET
          FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE DISSATISFIED WITH ANY
          PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF
          THE SERVICE.
        </P>
        <P>
          IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED “DISCLAIMER OF WARRANTIES” AND “LIMITATION OF
          LIABILITY” ARE INTENDED TO BE ONLY AS BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF ANY
          PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF
          SUCH PORTION SHALL NOT AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS.
        </P>
        <br />
        <SectionHeader>
          Dispute Resolution By Binding Arbitration:{' '}
          <Bold>PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.</Bold>
        </SectionHeader>
        <List>
          <OL type='a'>
            <li>
              <Bold>Agreement to Arbitrate</Bold>
              <P>
                This Dispute Resolution by Binding Arbitration section is referred to in this Terms of Service as the
                “Arbitration Agreement.” You agree that any and all disputes or claims that have arisen or may arise
                between you and Dutchie, whether arising out of or relating to this Terms of Service (including any
                alleged breach thereof), the Services, any advertising, any aspect of the relationship or transactions
                between us, shall be resolved exclusively through final and binding arbitration, rather than a court, in
                accordance with the terms of this Arbitration Agreement, except that you may assert individual claims in
                small claims court, if your claims qualify. Further, this Arbitration Agreement does not preclude you
                from bringing issues to the attention of federal, state, or local agencies, and such agencies can, if
                the law allows, seek relief against us on your behalf. You agree that, by entering into this Terms of
                Service, you and Dutchie are each waiving the right to a trial by jury or to participate in a class
                action. Your rights will be determined by a neutral arbitrator, not a judge or jury. The Federal
                Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement.
              </P>
            </li>
            <li>
              <Bold>Prohibition of Class and Representative Actions and Non-Individualized Relief</Bold>
              <P>
                <Bold>
                  <i>
                    YOU AND DUTCHIE AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS
                    AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR
                    PROCEEDING. UNLESS BOTH YOU AND DUTCHIE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN
                    MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
                    CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING
                    MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF
                    AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S),
                    EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE RELIEF UNDER
                    APPLICABLE LAW TO THE EXTENT REQUIRED FOR THE ENFORCEABILITY OF THIS PROVISION.
                  </i>
                </Bold>
              </P>
            </li>
            <li>
              <Bold>Pre-Arbitration Dispute Resolution</Bold>
              <P>
                Dutchie is always interested in resolving disputes amicably and efficiently, and most customer concerns
                can be resolved quickly and to the customer’s satisfaction by emailing customer support at{' '}
                <a href='mailto:support@dutchie.com'>support@dutchie.com</a>. If such efforts prove unsuccessful, a
                party who intends to seek arbitration must first send to the other, by certified mail, a written Notice
                of Dispute (“Notice”). The Notice to Dutchie should be sent to 2728 NW Potts Ct. Ste 100, Bend, OR 97703
                (“Notice Address”). The Notice must (i) describe the nature and basis of the claim or dispute and (ii)
                set forth the specific relief sought. If Dutchie and you do not resolve the claim within sixty (60)
                calendar days after the Notice is received, you or Dutchie may commence an arbitration proceeding.
                During the arbitration, the amount of any settlement offer made by Dutchie or you shall not be disclosed
                to the arbitrator until after the arbitrator determines the amount, if any, to which you or Dutchie is
                entitled.
              </P>
            </li>
            <li>
              <Bold>Arbitration Procedures</Bold>
              <P>
                Arbitration will be conducted by a neutral arbitrator in accordance with the American Arbitration
                Association’s (“AAA”) rules and procedures, including the AAA’s Consumer Arbitration Rules
                (collectively, the “AAA Rules”), as modified by this Arbitration Agreement. For information on the AAA,
                please visit its website,{' '}
                <a href='http://www.adr.org' target='_blank' rel='noreferrer'>
                  http://www.adr.org
                </a>
                . Information about the AAA Rules and fees for consumer disputes can be found at the AAA’s consumer
                arbitration page,
                <a href='http://www.adr.org/consumer_arbitration' target='_blank' rel='noreferrer'>
                  http://www.adr.org/consumer_arbitration
                </a>
                . If there is any inconsistency between any term of the AAA Rules and any term of this Arbitration
                Agreement, the applicable terms of this Arbitration Agreement will control unless the arbitrator
                determines that the application of the inconsistent Arbitration Agreement terms would not result in a
                fundamentally fair arbitration. The arbitrator must also follow the provisions of these Terms of Service
                as a court would. All issues are for the arbitrator to decide, including, but not limited to, issues
                relating to the scope, enforceability, and arbitrability of this Arbitration Agreement. Although
                arbitration proceedings are usually simpler and more streamlined than trials and other judicial
                proceedings, the arbitrator can award the same damages and relief on an individual basis that a court
                can award to an individual under the Terms of Service and applicable law. Decisions by the arbitrator
                are enforceable in court and may be overturned by a court only for very limited reasons.
              </P>
              <P>
                Unless Dutchie and you agree otherwise, any arbitration hearings will take place in a reasonably
                convenient location for both parties with due consideration of their ability to travel and other
                pertinent circumstances. If the parties are unable to agree on a location, the determination shall be
                made by AAA. If your claim is for $10,000 or less, Dutchie agrees that you may choose whether the
                arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a
                telephonic hearing, or by an in-person hearing as established by the AAA Rules. If your claim exceeds
                $10,000, the right to a hearing will be determined by the AAA Rules. Regardless of the manner in which
                the arbitration is conducted, the arbitrator shall issue a reasoned written decision sufficient to
                explain the essential findings and conclusions on which the award is based.
              </P>
            </li>
            <li>
              <Bold>Costs of Arbitration</Bold>
              <P>
                Payment of all filing, administration, and arbitrator fees (collectively, the “Arbitration Fees”) will
                be governed by the AAA Rules, unless otherwise provided in this Arbitration Agreement. If the value of
                the relief sought is $75,000 or less, at your request, Dutchie will pay all Arbitration Fees. If the
                value of relief sought is more than $75,000 and you are able to demonstrate to the arbitrator that you
                are economically unable to pay your portion of the Arbitration Fees or if the arbitrator otherwise
                determines for any reason that you should not be required to pay your portion of the Arbitration Fees,
                Dutchie will pay your portion of such fees. In addition, if you demonstrate to the arbitrator that the
                costs of arbitration will be prohibitive as compared to the costs of litigation, Dutchie will pay as
                much of the Arbitration Fees as the arbitrator deems necessary to prevent the arbitration from being
                cost-prohibitive. Any payment of attorneys’ fees will be governed by the AAA Rules.
              </P>
            </li>
            <li>
              <Bold>Confidentiality</Bold>
              <P>
                All aspects of the arbitration proceeding, and any ruling, decision, or award by the arbitrator, will be
                strictly confidential for the benefit of all parties.
              </P>
            </li>
            <li>
              <Bold>Severability</Bold>
              <P>
                If a court or the arbitrator decides that any term or provision of this Arbitration Agreement (other
                than the subsection (b) titled “Prohibition of Class and Representative Actions and Non-Individualized
                Relief” above) is invalid or unenforceable, the parties agree to replace such term or provision with a
                term or provision that is valid and enforceable and that comes closest to expressing the intention of
                the invalid or unenforceable term or provision, and this Arbitration Agreement shall be enforceable as
                so modified. If a court or the arbitrator decides that any of the provisions of subsection (b) above
                titled “Prohibition of Class and Representative Actions and Non-Individualized Relief” are invalid or
                unenforceable, then the entirety of this Arbitration Agreement shall be null and void, unless such
                provisions are deemed to be invalid or unenforceable solely with respect to claims for public injunctive
                relief. The remainder of the Terms of Service will continue to apply.
              </P>
            </li>
            <li>
              <Bold>Future Changes to Arbitration Agreement</Bold>
              <P>
                Notwithstanding any provision in this Terms of Service to the contrary, Dutchie agrees that if it makes
                any future change to this Arbitration Agreement (other than a change to the Notice Address) while you
                are a user of the Services, you may reject any such change by sending Dutchie written notice within
                thirty (30) calendar days of the change to the Notice Address provided above. By rejecting any future
                change, you are agreeing that you will arbitrate any dispute between us in accordance with the language
                of this Arbitration Agreement as of the date you first accepted these Terms of Service (or accepted any
                subsequent changes to these Terms of Service).
              </P>
            </li>
          </OL>
        </List>
        <br />
        <SectionHeader>Termination</SectionHeader>
        <P>
          You agree that Dutchie, in its sole discretion, may suspend or terminate your account (or any part thereof) or
          use of the Service and remove and discard any content within the Service, for any reason, including, without
          limitation, for lack of use or if Dutchie believes that you have violated or acted inconsistently with the
          letter or spirit of these Terms of Service. Any suspected fraudulent, abusive or illegal activity that may be
          grounds for termination of your use of Service, may be referred to appropriate law enforcement authorities.
          Dutchie may also in its sole discretion and at any time discontinue providing the Service, or any part
          thereof, with or without notice. You agree that any termination of your access to the Service under any
          provision of this Terms of Service may be effected without prior notice, and acknowledge and agree that
          Dutchie may immediately deactivate or delete your account and all related information and files in your
          account and/or bar any further access to such files or the Service. Further, you agree that Dutchie will not
          be liable to you or any third party for any termination of your access to the Service.
        </P>
        <br />
        <SectionHeader>User Disputes</SectionHeader>
        <P>
          You agree that you are solely responsible for your interactions with any other user in connection with the
          Service and Dutchie will have no liability or responsibility with respect thereto. Dutchie reserves the right,
          but has no obligation, to become involved in any way with disputes between you and any other user of the
          Service.
        </P>
        <br />
        <SectionHeader>General</SectionHeader>
        <P>
          These Terms of Service constitute the entire agreement between you and Dutchie and govern your use of the
          Service, superseding any prior agreements between you and Dutchie with respect to the Service. You also may be
          subject to additional terms and conditions that may apply when you use affiliate or third party services,
          third party content or third party software. These Terms of Service will be governed by the laws of the State
          of Oregon without regard to its conflict of law provisions. With respect to any disputes or claims not subject
          to arbitration, as set forth above, you and Dutchie agree to submit to the personal and exclusive jurisdiction
          of the state and federal courts located within Deschutes County, Oregon. The failure of Dutchie to exercise or
          enforce any right or provision of these Terms of Service will not constitute a waiver of such right or
          provision. If any provision of these Terms of Service is found by a court of competent jurisdiction to be
          invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’
          intentions as reflected in the provision, and the other provisions of these Terms of Service remain in full
          force and effect. You agree that regardless of any statute or law to the contrary, any claim or cause of
          action arising out of or related to use of the Service or these Terms of Service must be filed within one (1)
          year after such claim or cause of action arose or be forever barred. A printed version of this agreement and
          of any notice given in electronic form will be admissible in judicial or administrative proceedings based upon
          or relating to this agreement to the same extent and subject to the same conditions as other business
          documents and records originally generated and maintained in printed form. You may not assign this Terms of
          Service without the prior written consent of Dutchie, but Dutchie may assign or transfer this Terms of
          Service, in whole or in part, without restriction. The section titles in these Terms of Service are for
          convenience only and have no legal or contractual effect. Notices to you may be made via either email or
          regular mail. The Service may also provide notices to you of changes to these Terms of Service or other
          matters by displaying notices or links to notices generally on the Service.
        </P>
        <br />
        <SectionHeader>Your Privacy</SectionHeader>
        <P>
          At Dutchie, we respect the privacy of our users. For details please see our Privacy Policy. By using the
          Service, you consent to our collection and use of personal data as outlined therein.
        </P>
        <br />
        <SectionHeader>Notice for California Users</SectionHeader>
        <P>
          Under California Civil Code Section 1789.3, users of the Service from California are entitled to the following
          specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the
          California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112,
          Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210. You may contact us at Dutchie,
          Inc., 2728 NW Potts Ct. Ste 100, Bend, OR 97703, or by telephone at (866) 838-8244.
        </P>
        <br />
        <SectionHeader>Questions? Concerns? Suggestions?</SectionHeader>
        <P>
          Please contact us at <a href='mailto:support@dutchie.com'>support@dutchie.com</a> to report any violations of
          these Terms of Service or to pose any questions regarding this Terms of Service or the Service.
        </P>
        <br />
        {/* eslint-enable */}
      </PageContentContainer>
      <GlobalFooter />
    </Wrapper>
  );
}

export const PageContentContainer = styled.div`
  padding: ${({ isMobile }) => (isMobile ? `127px 25px 110px 25px` : `110px 245px 170px 245px`)};

  ${height}
`;

export const Bold = styled.span`
  font-weight: bold;
  i {
    font-style: italic;
  }
`;

export const H1 = styled(Text)`
  font-size: 26.87px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 20px;
  margin-top: 0;
`;

export const H2 = styled.h2`
  font-size: 1rem;
  font-weight: bolder;
  line-height: 1.5;
`;

export const SectionHeader = styled.h3`
  font-size: 1rem;
  font-weight: bolder;
  line-height: 1.5;
  margin-bottom: 20px;

  strong {
    text-decoration: none;
  }
`;

export const P = styled.p`
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 20px;
  padding-right: 10px;

  > a {
    color: ${({ theme }) => theme.colors.sapphire};
    font-weight: 700;
  }
`;

export const List = styled.div`
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 20px;
  padding-right: 10px;
`;

export const OL = styled.ol`
  counter-reset: list;
  margin: 15px 25px;
  li {
    list-style: none;
  }
  li:before {
    content: counter(list, lower-alpha) '. ';
    counter-increment: list;
    font-weight: bold;
  }
`;

export const UL = styled.ul`
  list-style: initial;
  margin: 15px 25px;
`;
